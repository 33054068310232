<template>
    <div class='grid'>
        <div class='col-12'>
            <div class='card'>
                <div class='card-header'>
                    <h4>Elenco Skills</h4>
                </div>
                <PickList
                    @move-to-source='onRemove'
                    @move-all-to-source='onRemove'
                    @moveAllToTarget='onAdd'
                    @reorder='onSort'
                    :show-source-controls='false'
                    @move-to-target='onAdd'
                    v-model="products"
                    listStyle="height:342px"
                    dataKey="id"
                >
                    <template #sourceheader> Non featured </template>
                    <template #targetheader> Featured </template>
                    <template #item="slotProps">
                        <div class="flex flex-wrap p-2 align-items-center gap-3">
                            <img class="w-4rem shadow-2 flex-shrink-0 border-round" :src="slotProps.item.icon || 'https://picsum.photos/48/48'" :alt="slotProps.item.slug" />
                            <div class="flex-1 flex flex-column gap-2">
                                <span class="font-bold">{{slotProps.item.slug}}</span>
                            </div>
                        </div>
                    </template>
                </PickList>
            </div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapActions, mapGetters } = createNamespacedHelpers('Users/CompanyTeamSkills');
const contStore = createNamespacedHelpers('Cont');
//Fetch all skills
import Notifications from '../../../mixins/notifications';
import Navigations from '../../../mixins/navigations';
import dd_companies from '../../../mixins/dd_companies';

const commonStore = createNamespacedHelpers('Common');
const teamStore = createNamespacedHelpers('Users/CompanyTeams');

export default {
    components: {},
    data() {
        return {
            products: null,
            teamName: ""
        };
    },
    computed: {
        ...mapGetters(['rows', 'loading']),
        ...contStore.mapGetters(['SkillsV2/rows', 'SkillsV2/loading']),
        ...teamStore.mapGetters(['row']),
        mapSelectedSkillIds(){
            const m = {};
            if (!this.rows) return m;
            if (this.rows.length === 0) return m;
            this.rows.forEach(row => {
                m[row['skill_id']] = row['ord'];
            })
            return m;
        },
        companyName(){
            if (this.company_id > 0) {
                const company = this.getCompany(this.company_id);
                if (!company) return "";
                return company.name;
            }
            return "";
        }
    },
    created() {
        this.initFilters();
    },
    mounted() {
        this.refreshList();
    },
    methods: {
        ...mapActions(['fetchList', 'addSkill', 'saveSkill', 'deleteSkill']),
        ...contStore.mapActions(['SkillsV2/fetchList']),
        ...commonStore.mapMutations(['setExtraBreadcrumbs']),
        ...teamStore.mapActions(['fetchOne']),
        refreshList() {
            if (!this.team_id) return;

            this.loadTeam(this.company_id, this.team_id);

            this.fetchList({
                company_id: this.company_id,
                team_id: this.team_id,
            }).then(() => {
                if(this['SkillsV2/rows'] && this['SkillsV2/rows'].length > 0) {
                    this.splitSkills();
                } else {
                    this.refreshSkills();
                }

            }).catch(err => {
                this.ShowStandardError(err.response);
            });
        },
        splitSkills(){
            const existing = this.mapSelectedSkillIds;
            const selected = {};
            const unselected = [];
            this['SkillsV2/rows'].forEach(skill => {
                if (existing[skill['id']]){
                    selected[skill['id']] = skill;
                } else {
                    unselected.push(skill);
                }
            });

            //do sort selected
            const sorted = [];
            this.rows.forEach(row => {
                sorted.push(selected[row['skill_id']]);
            })
            this.products = [unselected, sorted];
        },
        refreshSkills(){
            if (!this.team_id) return;

            this['SkillsV2/fetchList']().then(
                () => {
                    this.splitSkills();
                }
            ).catch(err => {
                this.ShowStandardError(err.response);
            });
        },
        onAdd(ev){
            if (!ev.items) return;
            if (!ev.items.length) return;
            let numBefore = this.products[1].length;
            let newOrd = numBefore * 10;
            ev.items.forEach(item => {
                newOrd += 10
                this.addSkill({
                    company_id: this.company_id,
                    team_id: this.team_id,
                    skill_id: item.id,
                    ord: newOrd
                }).then().catch(err => {
                    this.ShowStandardError(err.response);
                });
            })
        },
        onRemove: function(ev) {
            if (!ev.items) return;
            if (!ev.items.length) return;
            ev.items.forEach(item => {
                this.deleteSkill({
                    company_id: this.company_id,
                    team_id: this.team_id,
                    skill_id: item.id
                }).then().catch(err => {
                    this.ShowStandardError(err.response);
                });
            })
        },
        onSort(ev){
            if (!ev) return;
            if (!ev.value) return;
            if (!ev.value[1]) return;
            let newOrd = 0;
            ev.value[1].forEach(item => {
                newOrd += 10
                this.saveSkill({
                    company_id: this.company_id,
                    team_id: this.team_id,
                    skill_id: item.id,
                    ord: newOrd
                }).then().catch(err => {
                    this.ShowStandardError(err.response);
                });
            })
        },
        setPageBreadcrumbs(){
            if (this.company_id > 0 && this.team_id > 0) {
                this.setExtraBreadcrumbs([
                    { label: this.companyName, to: '/app/users/companies/'+this.company_id},
                    { label: "Teams", to: '/app/users/companies/'+this.company_id+'/company_teams'},
                    { label: this.teamName }
                ]);
            }
        },
        loadTeam(company_id, id){
            if (id === 0) return;
            this.fetchOne({id: id, company_id: company_id}).then(res => {
                this.teamName = res["name"];
                this.setPageBreadcrumbs();
            }).catch(err => {
                this.ShowStandardError(err.response);
            }).finally(() => {});

        }
    },
    props: {
        company_id: {
            default: 0,
            type: Number
        },
        team_id: {
            default: 0,
            type: Number
        }

    },
    watch:{
        team_id(){
            this.refreshList();
        },
        dd_mapped_companies(){
            this.setPageBreadcrumbs();
        }
    },
    mixins: [
        Notifications,
        Navigations,
        dd_companies
    ],
};
</script>
